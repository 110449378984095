import errorsMap from '@/helpers/errorsMap';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

class Response {
  constructor(response) {
    Object.assign(this, response);
    this.errors = this.getErrors();
  }

  get error() {
    if (this.isError() === false) {
      return null;
    }

    return this.hasErrors() ? this.errors[0] : DEFAULT_ERROR_MESSAGE;
  }

  getErrors() {
    if (this.hasValidationErrors()) {
      return this.getValidationErrors();
    }

    if (this.data instanceof Array) {
      if (this.data.length > 0 && this.data[0].message) {
        return this.data.map((error) => error.message);
      }
    }

    if (this.data instanceof Object) {
      return [this.data.message];
    }

    if (typeof this.data === 'string') {
      return [this.data];
    }

    return [];
  }

  getError() {
    return this.error;
  }

  isError() {
    return this.status >= 400 || this.status === undefined;
  }

  isSuccessful() {
    return this.status >= 200 && this.status < 300;
  }

  hasErrors() {
    return this.errors.length > 0;
  }

  hasValidationErrors() {
    return (
      this.data instanceof Array &&
      this.data.length > 0 &&
      typeof this.data[0].validatorKey === 'string'
    );
  }

  getValidationErrors() {
    /* eslint-disable no-param-reassign */
    return this.data.reduce((bag, error) => {
      const param = errorsMap.attributes[error.path] || error.path;
      const message = errorsMap.messages[error.validatorKey] || error.message;
      bag[param] = message;
      return bag;
    }, {});
  }
}

export default Response;
