<template>
  <div class="profile">
    <section ref="contentSection" class="banner profile__banner">
      <div class="container">
        <h2 class="t-white" v-text="$t('My profile')" />
      </div>
    </section>
    <load-component class="profile__content" :load="fetchProfile">
      <div slot="success" class="container">
        <success-message
          v-if="response && response.isSuccessful()"
          class="mb-5"
          :message="$t('Profile has been updated successfully')"
        />
        <error-message
          v-if="response && response.isError()"
          class="mb-5"
          :message="response.getError()"
        />
        <validation-observer ref="form" v-slot="{ invalid }" tag="div">
          <form @submit.prevent="updateProfile()">
            <div class="profile__row">
              <validation-provider
                v-slot="{ validate, errors }"
                class="profile__image"
                :name="$t('Profile image')"
                rules="image|mimes:image/*|size:30270"
              >
                <Avatar
                  :image="user.image"
                  class="profile__col is-inval"
                  @select="selectAvatar($event, validate)"
                  @cancel="cancelUpload($event, validate)"
                />
                <div class="form-validation w-auto mt-2">
                  <span
                    v-if="errors.length"
                    class="t-center w-100"
                    v-text="errors[0]"
                  ></span>
                </div>
              </validation-provider>
              <div class="profile__col">
                <div class="profile__form">
                  <validation-provider
                    v-slot="{ errors }"
                    class="form-group form-group--horizontal"
                    tag="div"
                    :name="$t('User name')"
                    vid="username"
                  >
                    <label
                      for="username"
                      class="form-label"
                      v-text="$t('User Name')"
                    ></label>
                    <div
                      class="form-validation"
                      :class="{ 'is-invalid': errors.length }"
                    >
                      <input
                        id="username"
                        v-model="form.username"
                        type="text"
                        :placeholder="$t('Jonny')"
                        class="form-control form-input--underline"
                      />
                      <span v-if="errors.length" v-text="errors[0]"></span>
                    </div>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    class="form-group form-group--horizontal"
                    tag="div"
                    rules="required|email"
                    :name="$t('Email')"
                    vid="email"
                  >
                    <label
                      for="email"
                      class="form-label"
                      v-text="$t('Email')"
                    ></label>
                    <div
                      class="form-validation"
                      :class="{ 'is-invalid': errors.length }"
                    >
                      <input
                        id="email"
                        v-model="form.email"
                        type="text"
                        :placeholder="$t('john.pitterson@gmail.com')"
                        class="form-control form-input--underline"
                      />
                      <span v-if="errors.length" v-text="errors[0]"></span>
                    </div>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    class="form-group form-group--horizontal"
                    tag="div"
                    :rules="
                      form.password &&
                      'required|min:8|confirmed:password_confirmation'
                    "
                    :name="$t('Password')"
                    vid="password"
                  >
                    <label
                      for="password"
                      class="form-label"
                      v-text="$t('Password')"
                    ></label>
                    <div
                      class="form-validation"
                      :class="{ 'is-invalid': errors.length }"
                    >
                      <input
                        id="password"
                        v-model="form.password"
                        autocomplete="new-password"
                        type="password"
                        :placeholder="$t('min 8 symbols')"
                        class="form-control form-input--underline"
                      />
                      <span v-if="errors.length" v-text="errors[0]"></span>
                    </div>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    class="form-group form-group--horizontal"
                    tag="div"
                    name="password_confirmation"
                  >
                    <label
                      for="password-confirmation"
                      class="form-label"
                      v-text="$t('Confirm password')"
                    ></label>
                    <div class="form-validation">
                      <input
                        id="password-confirmation"
                        v-model="form.password_confirmation"
                        type="password"
                        :placeholder="
                          $t('retype the password you have just created')
                        "
                        class="form-control form-input--underline"
                      />
                      <span v-if="errors.length" v-text="errors[0]"></span>
                    </div>
                  </validation-provider>

                  <p v-text="$t('Policy')"></p>
                </div>
                <div class="profile__divider"></div>
                <div class="profile__form">
                  <validation-provider
                    v-slot="{ errors }"
                    class="form-group"
                    :name="$t('Gender')"
                    tag="div"
                  >
                    <div class="mb-2 t-bold" v-text="$t('Gender')"></div>
                    <div
                      class="form-validation"
                      :class="{ 'is-invalid': errors.length }"
                    >
                      <div
                        v-for="gender in genders"
                        :key="gender"
                        class="form-check d-inline-block"
                      >
                        <label class="form-check__label d-flex mr-3 mb-0">
                          <input
                            v-model="form.gender"
                            class="form-check__input"
                            type="radio"
                            name="gender"
                            :value="gender"
                            tabindex="1"
                          />
                          <span
                            class="form-check__indicator"
                            tabindex="0"
                          ></span>
                          {{ $t(gender) }}
                        </label>
                      </div>
                    </div>
                    <div class="form-validation">
                      <span v-if="errors.length" v-text="errors[0]"></span>
                    </div>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    class="form-group form-group--horizontal mb-4"
                    tag="div"
                    :name="$t('Nationality')"
                    vid="nationality"
                  >
                    <label
                      for="nationality"
                      class="form-label"
                      @mousedown.prevent="setFocus('nationality')"
                      v-text="$t('Nationality')"
                    ></label>
                    <div
                      class="form-validation"
                      :class="{ 'is-invalid': errors.length }"
                    >
                      <single-select-underline
                        id="nationality"
                        ref="nationality"
                        v-model="form.nationality"
                        :options="nationalities"
                        :placeholder="$t('choose your nationality')"
                        :allow-empty="false"
                      />
                      <span v-if="errors.length" v-text="errors[0]"></span>
                    </div>
                  </validation-provider>

                  <birthdate
                    v-model="form.date_of_birth"
                    class="form-group profile__birthdate mb-4"
                  />

                  <p class="mb-4">
                    <!-- eslint-disable-next-line prettier/prettier -->
                      {{ $t('We use this information to filter reviews by users characteristics.') }}
                    <span
                      v-tooltip="$t('Filter explanation')"
                      class="t-underline t-orange t-semibold"
                      tabindex="0"
                      v-text="$t('Learn more')"
                    ></span>
                  </p>

                  <validation-provider
                    v-slot="{ errors }"
                    class="form-group profile__language"
                    tag="div"
                    :name="$t('Language')"
                    vid="language"
                  >
                    <label
                      for="language"
                      class="form-label"
                      @mousedown.prevent="setFocus('language')"
                      v-text="$t('Preferred Language')"
                    ></label>
                    <div
                      class="form-validation"
                      :class="{ 'is-invalid': errors.length }"
                    >
                      <single-select-underline
                        id="language"
                        ref="language"
                        v-model="form.language"
                        :options="languages"
                        :allow-empty="false"
                      />
                      <span v-if="errors.length" v-text="errors[0]"></span>
                    </div>
                  </validation-provider>

                  <button
                    class="btn btn--primary mt-4"
                    :disabled="invalid || loading"
                    :class="{ 'btn--disabled': invalid || loading }"
                    v-text="$t('Save')"
                  />
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
      <div slot="error" class="d-flex">
        <h3 class="mx-auto">{{ $t('Could not load data') }}</h3>
      </div>
    </load-component>
  </div>
</template>

<script>
import profile from '@/api/profile';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import LoadComponent from '@/components/loadComponent/LoadComponent';
import Response from '@/http/Response';
import SuccessMessage from '@/components/alerts/SuccessMessage';
import ErrorMessage from '@/components/alerts/ErrorMessage';
import nationalityMixin from '@/mixins/nationality';
import { mapGetters } from 'vuex';
import SingleSelectUnderline from '@/components/ui/singleselect/SingleSelectUnderline';
import Birthdate from './birthdate/Birthdate';
import Avatar from './avatar/Avatar';

export default {
  name: 'Profile',

  components: {
    Avatar,
    LoadComponent,
    ValidationObserver,
    ValidationProvider,
    SingleSelectUnderline,
    SuccessMessage,
    ErrorMessage,
    Birthdate,
  },

  mixins: [nationalityMixin],

  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
        confirmPassword: '',
        gender: '',
        nationality: '',
        language: '',
        date_of_birth: null,
        image: null,
      },
      genders: ['male', 'female', 'other'],
      languages: ['English', 'Italian'],
      response: null,
      loading: false,
      resetUpload: () => {},
    };
  },

  computed: {
    ...mapGetters({
      user: 'user/user',
      nationalities: 'nationalities/nationalities',
    }),
  },

  mounted() {
    this.form.oldPassword = '';
  },

  methods: {
    selectAvatar({ image, reset }, validate) {
      validate(image);

      if (image) {
        this.form.image = image;
      }

      this.resetUpload = reset;
    },

    cancelUpload(image, validate) {
      validate(image);

      this.form.image = image;
    },

    scrollToError(errors) {
      const errorsKeys = Object.keys(errors);
      const firstError = errorsKeys.find((errorKey) => errors[errorKey].length);
      if (firstError) {
        return this.$refs[firstError].$el.scrollIntoView();
      }
      return this.$refs.form.$el.scrollIntoView();
    },

    async fetchProfile() {
      this.fillForm(this.user);

      if (!this.nationalities.length) {
        await this.getNationalities();
      }
    },

    async updateProfile() {
      try {
        const valid = await this.$refs.form.validate();
        if (valid) {
          const formData = new FormData();
          const forKeys = Object.keys(this.form);
          forKeys.forEach((key) => formData.append(key, this.form[key]));
          const user = await profile.update(formData);
          this.fillForm(user);
          this.resetUpload();
          this.$store.commit('user/setUser', user);
          this.response = new Response({ status: 200 });
          this.$refs.contentSection.scrollIntoView({ behavior: 'smooth' });
        } else {
          const { errors } = this.$refs.form;
          this.scrollToError(errors);
        }
      } catch ({ response }) {
        this.response = new Response(response);
      }
    },

    fillForm(user) {
      this.form = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        gender: user.gender,
        nationality: user.nationality,
        language: user.language,
        image: user.image,
        username: user.username,
        date_of_birth: user.date_of_birth ? new Date(user.date_of_birth) : null,
      };
    },

    setFocus(ref) {
      this.$nextTick(() => {
        const el = this.$refs[ref].$el.querySelector('.multiselect');

        if (el) {
          el.focus();
        }
      });
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import '@/assets/styles/_banners.scss';
@import './profile.scss';
</style>
