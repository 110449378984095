<template>
  <fieldset>
    <label
      for="day"
      class="form-label"
      @mousedown.prevent="setFocusOnDay"
      v-text="$t('Date of birth')"
    ></label>
    <validation-observer v-slot="{ invalid }" ref="observer" tag="div">
      <div class="form-validation d-flex" :class="{ 'is-invalid': invalid }">
        <validation-provider
          ref="day"
          tag="div"
          mode="aggressive"
          :rules="rules"
          :name="$t('Day')"
          vid="day"
        >
          <single-select-underline
            v-model="day"
            class="mr-4"
            :options="days"
            :placeholder="$t('day')"
            :hide-selected="false"
            @input="update($event, validate)"
          />
        </validation-provider>
        <validation-provider
          ref="month"
          tag="div"
          :rules="rules"
          :name="$t('Month')"
          mode="aggressive"
          vid="month"
        >
          <single-select-underline
            v-model="month"
            class="mr-4"
            :options="months"
            :placeholder="$t('month')"
            :hide-selected="false"
            tracked-by="id"
            label="name"
            @input="update($event, validate)"
          />
        </validation-provider>
        <validation-provider
          ref="year"
          tag="div"
          mode="aggressive"
          :rules="rules"
          :name="$t('Year')"
          vid="year"
        >
          <single-select-underline
            v-model="year"
            :options="years"
            :placeholder="$t('year')"
            :hide-selected="false"
            @input="update($event, validate)"
          />
        </validation-provider>
      </div>
      <div class="form-validation">
        <span v-if="invalid" v-text="$t('Date field is required')"></span>
      </div>
    </validation-observer>
  </fieldset>
</template>

<script>
import SingleSelectUnderline from '@/components/ui/singleselect/SingleSelectUnderline';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  components: {
    SingleSelectUnderline,
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    value: {
      type: Date,
      default: null,
    },
  },

  data() {
    return {
      day: null,
      month: null,
      year: null,
    };
  },

  computed: {
    days() {
      return this.range(1, this.daysInMonth);
    },

    months() {
      return this.range(0, 11).map((month) => {
        return {
          id: month,
          name: this.formatMonth(month),
        };
      });
    },

    years() {
      return this.range(1900, new Date().getFullYear()).sort((a, b) => {
        return b - a;
      });
    },

    daysInMonth() {
      const month = this.month ? this.month.id + 1 : null;

      return new Date(this.year, month, 0).getDate();
    },

    isLeapYear() {
      return !this.year || new Date(this.year, 1, 29).getDate() === 29;
    },

    date() {
      if (!this.year || !this.month || !this.day) {
        return null;
      }

      return new Date(this.year, this.month?.id || null, this.day);
    },

    isTouched() {
      return this.year || this.day || typeof this.month?.id === 'number';
    },

    rules() {
      return this.isTouched ? 'required' : '';
    },
  },

  created() {
    this.parseDate();
  },

  methods: {
    async update() {
      await this.validateDay();

      this.$refs.day.validate();
      this.$refs.month.validate();
      this.$refs.year.validate();

      this.$emit('input', this.date);
    },

    validateDay() {
      if (!this.isLeapYear && this.month?.id === 1 && this.day === 29) {
        this.day = null;
      }

      if (this.year && this.day > this.daysInMonth) {
        this.day = 1;
      }
    },

    parseDate() {
      const date = this.normalizeDate(this.value);

      if (!date) {
        return;
      }

      this.day = date.getDate();
      this.year = date.getFullYear();
      this.month = this.months.find((month) => month.id === date.getMonth());
    },

    normalizeDate(date) {
      if (date instanceof Date && !Number.isNaN(+date)) {
        return date;
      }

      return undefined;
    },

    formatMonth(month, format = 'long') {
      return new Date(2021, month).toLocaleString(this.$i18n.locale, {
        month: format,
      });
    },

    range(first, last) {
      const range = [];

      for (let i = first; i <= last; i += 1) {
        range.push(i);
      }

      return range;
    },

    setFocusOnDay() {
      this.$nextTick(() => {
        const el = this.$refs.day.$el.querySelector('.multiselect');

        if (el) {
          el.focus();
        }
      });
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>
